import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/utils/Loading";
import Slider from "../components/utils/Slider";
import { DownloadCV } from "../components/utils/DownloadCV";
import ContactDetails from "../components/Contact/ContactDetails";
import Markup from "../components/utils/Markup";
import Icon from "../components/styles/Icons";
import { useData } from "../utils/useData";

const index = () => {
  // const { personal } = useContext(AppContext);
  let personal = useData("personal");
  const [sliderItems, setSliderItems] = useState([]);
  const aboutMe = personal.filter((item) => item.label === "about me");

  useEffect(() => {
    setSliderItems(personal.filter((item) => item.label === "slider"));
  }, [personal]);

  if (!personal || personal.length <= 0) {
    return <Loading />;
  }

  return (
    <div className="section-inner start-page-content swing-in-top-bck">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <div className="photo">
              <img
                width="100%"
                height="100%"
                src="../static/images/profile.webp"
                alt="esteban flores"
              />
            </div>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-8">
            <div className="title-block">
              <h1 className="text-capitalize ">Esteban Flores</h1>
              <Slider items={sliderItems} />
            </div>

            <div className="social-links">
              <Icon
                href="https://www.linkedin.com/in/esteban-flores/"
                aria-label="Esteban Flores LinkedIn Account Link"
              >
                <span className="fab fa-github"></span>
              </Icon>
              <Icon
                href="https://github.com/estebanfloresf"
                aria-label="Esteban Flores Github Account Link"
              >
                <span className="fab fa-linkedin-in"></span>
              </Icon>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <div className="about-me">
              <div className="block-title">
                <h2>
                  About
                  <span>Me</span>
                </h2>
              </div>
              <div
                className="self-description"
                dangerouslySetInnerHTML={Markup(aboutMe[0].value, false)}
              />
            </div>

            <DownloadCV />
          </div>

          <div className="col-sm-6 col-md-6 col-lg-6">
            <ContactDetails personal={personal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
