import React from "react";
import { formatNumber } from "libphonenumber-js";
import { getYear } from "../../utils/helpers";
import Markup from "../utils/Markup";

const ContactDetails = ({ personal }) => {
  const filter_personal = personal.filter(
    (item) => item.contactSection && item
  );
  return (
    <ul className="info-list">
      {filter_personal.map((item) => {
        const { id, label, value } = item;
        var value_html = Markup(value, true);

        if (label === "age") {
          value_html = getYear(value_html);
        }

        return (
          <li key={id}>
            <span className="title text-capitalize">{label}</span>
            <span
              className={` ${label !== "email" && "text-capitalize"} value`}
            >
              {label === "email" ? (
                <a href={`mailto:${value_html}`}>{value_html} </a>
              ) : label === "phone" ? (
                <a href={`tel:${value_html}`}>
                  {formatNumber(
                    {
                      country: "EC",
                      phone: `${value_html}`,
                    },
                    "International"
                  )}
                </a>
              ) : (
                value_html
              )}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default ContactDetails;
