import React from "react";

export const DownloadCV = () => {
  return (
    <div className="download-resume">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="block">
            <div className="center download-resume">
              <a
                download
                href="../../static/Esteban_Flores_CV_ENG.pdf"
                className="btn btn-secondary"
              >
                Download Resume
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
