// Some details about the site
export const siteName = `Esteban Flores`;

export function unixToDate(unixTime) {
  const milliseconds = unixTime * 1000; // 1575909015000
  const dateObject = new Date(milliseconds);
  const month = dateObject.toLocaleString("default", { month: "short" });
  const year = dateObject.toLocaleString("en-US", { year: "numeric" }); // 1989

  //   return moment.unix(unixTime).format("MMM YYYY");
  return `${month} ${year}`;
}

export function getYear(date) {
  const milliseconds = date * 1000; // 1575909015000
  const dateObject = new Date(milliseconds);
  const yearDate = dateObject.toLocaleString("en-US", { year: "numeric" }); // 1989
  return new Date().getFullYear() - yearDate;
}

export const icons = [
  {
    residence: "fa-map-marker",
  },
  {
    email: "fa-envelope",
  },
  {
    phone: "fa-phone",
  },
  {
    freelance: "fa-check",
  },
];
