import React, { useState } from "react";
import Markup from "../utils/Markup";
import SliderContainer from "../styles/Slider";
import useInterval from "./useInterval";

import { CSSTransition, TransitionGroup } from "react-transition-group";

const Slider = ({ items }) => {
  const [pos, setPosition] = useState(0);
  const duration = 4000;

  useInterval(() => {
    // Your custom logic here
    if (pos >= items.length - 1) {
      setPosition(0);
    } else {
      setPosition(pos + 1);
    }
  }, duration);

  return (
    items.length > 0 && (
      // <TransitionGroup component={null}>
      <CSSTransition classNames="slider" timeout={duration}>
        <SliderContainer>
          <div dangerouslySetInnerHTML={Markup(items[pos].value, false)} />
        </SliderContainer>
      </CSSTransition>
      // </TransitionGroup>
    )
  );
};

export default Slider;
